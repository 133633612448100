<template>
  <div v-if="inquiry" class="template-inquiry-detail">
    <div class="title main" :class="isMobile ? 'h7' : 'h5'">
      문의 답변
    </div>
    <div class="template-inquiry-item">
      <div class="template-inquiry-parnter-info">
        <img class="prod-img" :src="inquiry.img" />
        <div class="flex-column">
          <div class="body5 sub3">{{ inquiry.template_partner }}</div>
          <div class="body2-bold main">{{ inquiry.product }}</div>
          <div class="body4 main">{{ priceFormat(inquiry.price) }}</div>
        </div>
      </div>
      <div class="line"></div>
      <div>
        <!-- 질문 -->
        <div class="flex-between">
          <div>
            <div class="flex-align">
              <span
                class="body7 gray3"
                :class="{ answered: inquiry.answer_content, 'not-answered': !inquiry.answer_content }"
              >
                {{ inquiry.answer_content ? '답변완료' : '미답변' }}</span
              >
              <span class="body6 sub3 margin-left-8">{{ datesFormat(inquiry.created_time, 'date_3') }}</span>
              <span class="gray1 margin-left-8 margin-right-8">|</span>
              <span class="body6 sub3">{{ authorFormat(inquiry.author) }} </span>
            </div>
          </div>
        </div>
        <!-- 답변 -->
        <div class="inquiry-answer-wrapper margin-top-16">
          <div class="flex cursor-pointer">
            <div class="body4-bold primary">Q</div>
            <div class="subtitle7 sub margin-left-8 ellipsis-1" style="width:970px">
              {{ inquiry.title ? inquiry.title : '질문' }}
            </div>
          </div>
          <div class="body4 sub margin-top-8">{{ inquiry.question_content }}</div>
          <div>
            <div class="flex-align answer-title" style="gap:8px">
              <div class="subtitle6 sub">답변</div>
              <div class="subtitle6 error">*</div>
            </div>
            <lp-textarea
              :value.sync="answer_content"
              placeholder="답변을 압력해주세요."
              :max-length="1000"
              :rows="4"
              :cols="50"
            />
            <div class="flex-center">
              <button class="button is-primary answer-btn" @click="submitAnswer">
                답변 {{ inquiry.answer_content ? '수정' : '등록' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PartnerAPIMixin from '@/mixins/PartnerAPIMixin';
import LpTextarea from '../component/LpTextArea.vue';
export default {
  name: 'UserTemplateInquiryDetail',
  mixins: [PartnerAPIMixin],
  components: { LpTextarea },
  data() {
    return {
      inquiry: null,
      answer_content: '',
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.request.partner.get(`user_product/inquiry/${this.$route.query.id}`).then((res) => {
        this.inquiry = res.data;
      });
    },
    authorFormat(author) {
      if (author.length > 2) {
        return author[0] + '*'.repeat(author.length - 2) + author[author.length - 1];
      } else if (author.length === 2) {
        return author[0] + '*';
      } else {
        return author;
      }
    },
    priceFormat(price) {
      let result = this.currency(price.price);
      if (price.won_type === 1) {
        result += '~';
      } else if (price.won_type === 2) {
        result += ' 초반대';
      } else if (price.won_type === 3) {
        result += ' 후반대';
      }
      return result;
    },
    submitAnswer() {
      let param = {
        user_id: this.user.partner_id,
        user_type: 'partner',
        answer_content: this.answer_content,
      };
      this.request.partner
        .patch(`user_product/inquiry/${this.$route.query.id}`, param)
        .then(() => {
          this.toast(`답변이 ${this.inquiry.answer_content ? '수정' : '등록'}되었습니다.`);
          this.$router.push('/partner_center/customer_inquiry/user_template_inquiry');
        })
        .catch((err) => {
          this.toast(err.response.data.message);
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.title
  padding 40px 0 24px

.answer-title
  margin 40px 0 8px

.answer-btn
  width 240px
  height 54px
  margin-top 40px

.template-inquiry-item
  display flex
  flex-direction column
  gap 12px
  padding 24px
  border-radius 12px
  background-color #fff

.template-inquiry-parnter-info
  display grid
  grid-template-columns 54px 1fr
  gap 12px
  align-items center

.prod-img
  width 54px
  height 68px
  border-radius 5px
  object-fit cover
  border 1px solid #EEE;

.line
  width 100%
  height 1px
  background-color #EEE

.answered
.not-answered
  padding 3px 8px
  border-radius 4px

.answered
  background-color #FF6600
  color #FFF

.not-answered
  background-color #FFF0E5
  color $primary

@media (max-width:425px)
  .template-inquiry-detail
    padding 16px
  .title
    padding 24px 0 16px
  .answer-btn
    width 100%
</style>
